import { FC } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';
import img from '~assets/comunicado-temporada-alta.jpg';
import { X } from 'react-feather';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'& .MuiPaper-root': {
				maxWidth: '60vw'
			}
		},
		dialogContent: {
			paddingTop: '0 !important',
			'& > img': {
				width: '100%'
			}
		},
		closeButton: {
			padding: '0',
			display: 'flex',
			justifyContent: 'flex-end',
			'& > button': {
				color: theme.palette.grey[500]
			}
		}
	})
);

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

const OneTimeNotification: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
	const classes = useStyles();

	return (
		<Dialog
			open={isOpen}
			maxWidth='lg'
			fullWidth
			disableEscapeKeyDown
			className={classes.container}
		>
			<MuiDialogTitle disableTypography className={classes.closeButton}>
				<IconButton aria-label='close' onClick={onClose}>
					<X />
				</IconButton>
			</MuiDialogTitle>
			<DialogContent className={classes.dialogContent}>
				<img src={img} alt='Aviso Temporada Alta' />
			</DialogContent>
		</Dialog>
	);
};

export default OneTimeNotification;
