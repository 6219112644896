import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles';
import { Grid, createStyles, CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Routes from './Routes';
import axiosInstance, { instanceRate, instancePayment } from './util/axios';
import LogRocket from 'logrocket';
import { shallow } from 'zustand/shallow';

// Drawers
import LDrawer from './components/Navigation/LDrawer';
import TopNav from './components/Navigation/TopNav';
import RDrawer from './components/Navigation/RDrawer';
import SnackBarToast from './components/Navigation/SnackBarToast';

// Auth
import SignInPage from './pages/auth/SignInPage';
import CreatePasswordPage from './pages/auth/CreatePasswordPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';

import { useTheme } from './hooks/useTheme';
import AuthContext from './context/AuthContext';
import DrawContext from './context/DrawContext';
import LoaderPage from './pages/loader/LoaderPage';
import { UserDetails } from './store/actions/ActionTypes';
import { END_POINT_WS, LOGROCKET_ENABLE, LOGROCKET_KEY } from './config';
import useReportExportsStore from '~store/newstore/useReportExportsStore';
import useNotificationStore from '~store/newstore/useNotificationStore';
import ShipmentTracking from '~pages/tracking/ShipmentTracking';
import { io } from 'socket.io-client';
import ServerUpdatedModal from '~components/Modals/ServerUpdatedModal';
import HomeNotificationModal, { NotificationItem } from '~components/Modals/HomeNotificationModal';
import OneTimeNotification from '~components/Modals/OneTimeNotification';

const useStyles = makeStyles(() =>
	createStyles({
		mainContainer: {
			marginTop: 80,
			marginBottom: 80
		}
	})
);

const App: React.FC = () => {
	const classes = useStyles();

	const { themeV4, themeV5, currentTheme, toggleTheme, generateClassName } = useTheme(false);
	const { setIsCheckingIsAuth, setAuth, setCurrentUser, isCheckingIsAuth, isAuth, currentUser } =
		useContext(AuthContext);
	const { rCardStatus } = useContext(DrawContext);
	const [reportExports, removeReportExports] = useReportExportsStore(
		(state) => [state.reportExports, state.removeReportExports],
		shallow
	);
	const [setNotificationQueue, clearNotificationQueue] = useNotificationStore(
		(state) => [state.setNotificationQueue, state.clearNotificationsQueue],
		shallow
	);

	const [showServerUpdateAlert, setShowServerUpdateAlert] = useState(false);
	const [showDispersionAlert, setShowDispersionAlert] = useState(false);
	const [notificationAlert, setNotificationAlert] = useState<NotificationItem | null>(null);
	const [showOneTimeAlert, setShowOneTimeAlert] = useState(false);

	const handleRealod = () => {
		window.location.reload();
	};

	// Confirm read notification
	const handleReadNotification = useCallback(async () => {
		setShowDispersionAlert(false);
		setNotificationAlert(null);
	}, []);

	useEffect(() => {
		const socket = io(END_POINT_WS || '');

		socket.on('serverUpdated', () => {
			setShowServerUpdateAlert(true);
		});

		socket.on(`notifications_${currentUser.id}`, (...newDispersions) => {
			if (!Array.isArray(newDispersions) || !newDispersions?.length) return;
			setShowDispersionAlert(true);
			setNotificationAlert(newDispersions[0]);
		});

		return () => {
			socket.disconnect();
		};
	}, [currentUser.id]);

	// Validate Token
	useEffect(() => {
		(async () => {
			const token = localStorage.getItem('auth');
			if (token) {
				try {
					axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					instanceRate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					instancePayment.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					const userDetails = await axiosInstance.get('/api/user');
					setCurrentUser(userDetails.data as UserDetails);
					setAuth(true);

					// Check last available version
					const response = await axiosInstance.get('/api/version');
					const lastVersion = response.data.version;
					const currentVersion = localStorage.getItem('version');
					const oneTimeAlert = localStorage.getItem('oneTimeAlert');
					// if there is no current version, we set the last version and force to update just in case
					// if there is a current version, we compare it with the last version and force to update just in case
					if (!currentVersion || currentVersion !== lastVersion) {
						setShowServerUpdateAlert(true);
						localStorage.setItem('version', lastVersion);
					}

					// High season alert
					if (!oneTimeAlert) {
						setShowOneTimeAlert(true);
						localStorage.setItem('oneTimeAlert', 'oneTimeAlert');
					}
				} catch (error) {
					localStorage.removeItem('auth');
					setAuth(false);
					axiosInstance.defaults.headers.common['Authorization'] = undefined;
					instanceRate.defaults.headers.common['Authorization'] = undefined;
					instancePayment.defaults.headers.common['Authorization'] = undefined;
					clearNotificationQueue();
				}
			}
			setIsCheckingIsAuth(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth, setIsCheckingIsAuth]);

	// Queue for reports
	useEffect(() => {
		const intervalId = setInterval(async () => {
			if (reportExports.length === 0) {
				clearInterval(intervalId);
			}
			for (const report of reportExports) {
				try {
					const status = await instanceRate.get(`/reports/labels/${report.id}`);
					if (status.data.status === 'done') {
						window.open(report.url, '_blank');
						setNotificationQueue({
							message: `El Reporte ${
								new Date().toISOString().split('T')[0]
							} esta listo. Da click para verlo`,
							date: new Date(),
							callBack: () => {
								window.open(report.url, '_blank');
							}
						});
						removeReportExports(report.spreadsheet_id);
					}
				} catch (e) {
					console.error(e);
					setNotificationQueue({
						message: `El Reporte ${
							new Date().toISOString().split('T')[0]
						} no se pudo procesar, intente más tarde`,
						date: new Date()
					});
					clearInterval(intervalId);
					removeReportExports(report.spreadsheet_id);
				}
			}
		}, 1000 * 5); // in milliseconds

		return () => clearInterval(intervalId);
	}, [reportExports, setNotificationQueue, removeReportExports]);

	// Enable logRocket
	useEffect(() => {
		if (currentUser?.id !== 0 && LOGROCKET_ENABLE) {
			LogRocket.init(LOGROCKET_KEY);
			LogRocket.identify(currentUser.id.toString(), {
				email: currentUser.email,
				name: currentUser.name
			});
		}
	}, [currentUser]);

	// Shows the user a load while checking
	if (isCheckingIsAuth) {
		return (
			<StylesProvider generateClassName={generateClassName}>
				<ThemeProviderV4 theme={themeV4}>
					<ThemeProviderV5 theme={themeV5}>
						<CssBaseline />
						<LoaderPage />
					</ThemeProviderV5>
				</ThemeProviderV4>
			</StylesProvider>
		);
	}

	// If is not auth, the pages to login or singup
	if (!isAuth) {
		return (
			<StylesProvider generateClassName={generateClassName}>
				<ThemeProviderV4 theme={themeV4}>
					<ThemeProviderV5 theme={themeV5}>
						{/* Toasts (snackbar)*/}
						<SnackBarToast />
						<CssBaseline />
						<Router>
							<Switch>
								<Route path='/auth' exact component={SignInPage} />
								<Route path='/tracking/:id' exact component={ShipmentTracking} />
								<Route
									path='/validate-account/:validateToken'
									exact
									component={CreatePasswordPage}
								/>
								<Route path='/newpassword/:validateToken' exact component={CreatePasswordPage} />
								<Route path='/forgotpassword' exact component={ForgotPasswordPage} />
								<Redirect to='/auth' />
							</Switch>
						</Router>
					</ThemeProviderV5>
				</ThemeProviderV4>
			</StylesProvider>
		);
	}

	// He or She is an authenticated user
	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProviderV4 theme={themeV4}>
				<ThemeProviderV5 theme={themeV5}>
					<CssBaseline />
					<Router>
						<ServerUpdatedModal
							isOpen={showServerUpdateAlert}
							onClose={() => setShowServerUpdateAlert(false)}
							onConfirm={handleRealod}
						/>
						<HomeNotificationModal
							isOpen={showDispersionAlert}
							onFinish={handleReadNotification}
							notification={notificationAlert}
						/>
						<OneTimeNotification
							isOpen={showOneTimeAlert}
							onClose={() => setShowOneTimeAlert(false)}
							onConfirm={handleRealod}
						/>
						{/* Toasts (snackbar)*/}
						<SnackBarToast />
						{/* Top Nav Bar */}
						<TopNav currentTheme={currentTheme} toggleTheme={toggleTheme} />
						{/* Left Menu */}
						<LDrawer />
						{/* Rigth Menu */}
						{rCardStatus !== 'HIDDEN' && <RDrawer />}
						{/* Main Layout */}
						<Grid container justifyContent='flex-start'>
							{/* Space for Left Draw */}
							<Grid item xs={1} />
							{/* Main Layout */}
							<Grid
								item
								xs={10}
								//md={10}
								lg={rCardStatus !== 'HIDDEN' ? 8 : 10}
								container
								direction='row'
								justifyContent='center'
								alignItems='flex-start'
								className={classes.mainContainer}
							>
								<Routes />
							</Grid>
							{/* Space for Right Draw */}
							<Grid item xs={1} />
						</Grid>
					</Router>
				</ThemeProviderV5>
			</ThemeProviderV4>
		</StylesProvider>
	);
};

export default App;
