import axiosInstance from '../../util/axios';
import axios from 'axios';
import { Dispatch } from 'redux';
import { RootStore } from '../store';
import {
	DataActions,
	CLEAR_ADDRESS_DATA,
	ADD_ADDRESS_DATA,
	ADD_PACKAGE_TEMP_DATA,
	CLEAR_ERROR_FETCH_DATA,
	CLEAR_SERVICES_DATA,
	FETCH_ADDRESSES_DATA,
	FETCH_FAIL_DATA,
	FETCH_INIT_DATA,
	FETCH_PACKAGES_DATA,
	FETCH_SUCCESS_DATA,
	POST_GUIDE_DATA,
	POST_RATE_DATA,
	POST_PICKUP_DATA,
	SET_BATCH_ID,
	ServiceResponse,
	Package,
	ConsolidatedActions,
	SET_CARRIERS_CONSOLIDATED,
	NewAddress,
	REMOVE_PACKAGE,
	FlowActions,
	SendActions,
	SET_UNIQUE_CARRIER_SERVICES_FLOW,
	SET_FILTERED_SERVICES,
	EDIT_PACKAGE_TEMP_DATA,
	PickUpActions,
	CAN_MOVE_TO_BOOKLET_PICKUP,
	CLEAR_PARCEL_NUMBERS,
	SHOW_GLOBAL_INSURANCE_FLOW,
	RateRequest,
	SET_CARRIERS,
	CarrierList
} from './ActionTypes';
import { removeStress } from '~util/removeStress';
import capitalize, { capitalizeAll } from '~util/capitalize';
import { splitTextIntoLines } from '~util/preview';
import { CARRIERS } from '~util/logos';

// Get addreses fot the booklet
export const fetchAddresses = () => async (dispatch: Dispatch<DataActions>) => {
	dispatch({ type: FETCH_INIT_DATA });

	// fetch only once? //TODO add refresh button
	//console.log('getting addreses');

	try {
		const result = await axiosInstance.get('/api/addresses');
		//console.log('addreses result', result);
		dispatch({ type: FETCH_SUCCESS_DATA });
		const addresses: NewAddress[] = result.data.map((addr: any) => ({
			id: addr.address.id,
			address: addr.address.address,
			alias: addr.address.alias,
			scope:
				addr.scope === 'global' ? 'global' : addr.scope === 'user' ? 'Personal' : 'departamental',
			type: addr.address.type,
			coordinates: addr.address.coordinates,
			street: addr.address.street,
			number: addr.address.number,
			suite_number: addr.address.suite_number,
			city: addr.address.city,
			business_name: addr.address.business_name,
			neighborhood: addr.address.neighborhood,
			state: addr.address.state,
			zip_code: addr.address.zip_code,
			country: addr.address.country,
			contacts: addr.address.contacts
		}));
		dispatch({ type: FETCH_ADDRESSES_DATA, addresses: addresses });
	} catch (error) {
		//console.log('fetch address error', error.response);
		dispatch({
			type: FETCH_FAIL_DATA,
			message: 'No se pudo obtener las direcciones'
		});
	}
};

export const removeAddress = (id: string) => async (dispatch: Dispatch<DataActions>) => {
	try {
		if (id.startsWith('t')) {
			dispatch({ type: REMOVE_PACKAGE, id });
		} else {
			await axiosInstance.delete(`/api/address/${id}`);
		}
	} catch (error) {
		//console.error('Error when deleting package', error?.response);
	}
};

export const clearAddressesData = (): DataActions => {
	return { type: CLEAR_ADDRESS_DATA };
};

export const fetchPackages =
	() => async (dispatch: Dispatch<DataActions>, getStore: () => RootStore) => {
		dispatch({ type: FETCH_INIT_DATA });
		const { data } = getStore();

		if (!data.packages.length) {
			try {
				const response = await axiosInstance.get('/api/items');
				//console.log('fetched packages', response);
				dispatch({ type: FETCH_SUCCESS_DATA });
				const packages: Package[] = response.data.map((pkg: any) => ({
					id: pkg.id,
					alias: pkg.alias,
					type: pkg.type,
					content: pkg.content,
					description: pkg.description,
					length: pkg.length,
					height: pkg.height,
					width: pkg.width,
					weight: pkg.weight
				}));

				dispatch({ type: FETCH_PACKAGES_DATA, packages });
			} catch (error) {
				//console.log('Error fetching packages', error.response);
				dispatch({
					type: FETCH_FAIL_DATA,
					message: 'No se pudo obtener las direcciones'
				});
			}
		}
	};

export const addTempAddress = (address: NewAddress): DataActions => {
	return { type: ADD_ADDRESS_DATA, address };
};

export const createAddress = (address: NewAddress) => async (dispatch: Dispatch<DataActions>) => {
	dispatch({ type: FETCH_INIT_DATA });
	try {
		const payload = {
			address: {
				...address
			},
			scope: address.scope
		};
		// TODO dispatch
		//dispatch({ type: ADD_ADDRESS_TEMP, address: {...address, id: new Date().toISOString()} as Address });
		// const result =
		await axiosInstance.post('/api/address', payload);
		//console.log('[Data Actions] create address', result);
		dispatch({ type: FETCH_SUCCESS_DATA });
	} catch (error) {
		//console.log('create address error', error.response);
		dispatch({ type: FETCH_FAIL_DATA, message: 'No se pudo crear' });
	}
};

export const addTempPackage = (pkg: Package): DataActions => {
	return { type: ADD_PACKAGE_TEMP_DATA, package: pkg };
};

export const updateTempPackage = (pkg: Package): DataActions => {
	return { type: EDIT_PACKAGE_TEMP_DATA, package: pkg };
};

export const createPackage = (pkg: Package) => async (dispatch: Dispatch<DataActions>) => {
	dispatch({ type: FETCH_INIT_DATA });
	try {
		const payload = {
			...pkg
		};
		const response = await axios.post('/api/item', payload);
		//console.log('Created Package', response);
		dispatch({ type: FETCH_SUCCESS_DATA });
		dispatch({ type: ADD_PACKAGE_TEMP_DATA, package: response.data });
	} catch (error) {
		//console.error('Error when creating package', error?.response);
		dispatch({ type: FETCH_FAIL_DATA, message: 'No se pudo crear el paquete' });
	}
};

export const removePackage = (id: string) => async (dispatch: Dispatch<DataActions>) => {
	try {
		if (id.startsWith('t')) {
			dispatch({ type: REMOVE_PACKAGE, id });
		} else {
			await axiosInstance.delete(`/api/item/${id}`);
		}
	} catch (error) {
		//console.error('Error when deleting package', error?.response);
	}
};

export const clearError = (): DataActions => {
	return { type: CLEAR_ERROR_FETCH_DATA };
};

export const postRate =
	(notification?: (message: string) => void) =>
	async (
		dispatch: Dispatch<DataActions | ConsolidatedActions | FlowActions | SendActions>,
		getStore: () => RootStore
	) => {
		const { send, simulatedUser, rate, flow } = getStore();

		try {
			dispatch({ type: FETCH_INIT_DATA });
			dispatch({ type: CLEAR_SERVICES_DATA });
			dispatch({ type: SET_FILTERED_SERVICES, services: [] });

			let ensuredPackages = 0;

			const pkgs =
				flow.currentFlow === 'send' ? send.currentPackagesSend : rate.currentPackagesRate;

			const pkgsPaylod = pkgs.reduce((result: any, p) => {
				let finalInsurance = 0;
				if (p.insurance && p.insurance > 0) {
					finalInsurance = p.insurance;
					ensuredPackages = ensuredPackages + (p.quantity ?? 1);
				}

				return result.concat(
					new Array(p.quantity).fill(p).map((p) => {
						if (finalInsurance > 0) {
							return p.type === 'Sobre'
								? {
										length: 30,
										weight: p.weight,
										height: 2,
										width: 26,
										// Server wants insurance with decimals even if the user input an integer
										insurance: +finalInsurance.toFixed(2),
										type: p.type,
										merchandise: p.merchandise,
										is_irregular_delivery: p.is_irregular_delivery ?? false,
										measurement: 'cm'
								  }
								: {
										length: p.length,
										weight: p.weight,
										height: p.height,
										width: p.width,
										// Server wants insurance with decimals even if the user input an integer
										insurance: +finalInsurance.toFixed(2),
										type: p.type,
										merchandise: p.merchandise,
										is_irregular_delivery: p.is_irregular_delivery ?? false,
										measurement: p.measurement
								  };
						}
						return p.type === 'Sobre'
							? {
									length: 30,
									weight: p.weight,
									height: 2,
									width: 26,
									type: p.type,
									merchandise: p.merchandise,
									is_irregular_delivery: p.is_irregular_delivery ?? false,
									measurement: 'cm'
							  }
							: {
									length: p.length,
									weight: p.weight,
									height: p.height,
									width: p.width,
									type: p.type,
									merchandise: p.merchandise,
									is_irregular_delivery: p.is_irregular_delivery ?? false,
									measurement: p.measurement
							  };
					})
				);
			}, []);

			if (flow.showGlobalInsuranceFlow) {
				dispatch({
					type: SHOW_GLOBAL_INSURANCE_FLOW,
					show: ensuredPackages > 0
				});
				//dispatch({ type: DISABLE_GLOBAL_INSURANCE_FLOW });
			}

			const sendFlow = flow.currentFlow === 'send';

			const dcoords = sendFlow
				? send.destinationAddressSend?.coordinates
				: rate.destinationAddressRate?.coordinates;
			const ocoords = sendFlow
				? send.originAddressSend?.coordinates
				: rate.originAddressRate?.coordinates;

			const neighborhood_orgin = sendFlow
				? send.originAddressSend?.neighborhood
				: rate.originAddressRate?.neighborhood;
			const state_destination = sendFlow
				? send.destinationAddressSend?.state
				: rate.destinationAddressRate?.state;
			const city_destination = sendFlow
				? send.destinationAddressSend?.city
				: rate.destinationAddressRate?.city;

			const neighborhood_destionation = sendFlow
				? send.destinationAddressSend?.neighborhood
				: rate.destinationAddressRate?.neighborhood;

			const payload: RateRequest = {
				packages: pkgsPaylod,
				zip_destination: sendFlow
					? send.destinationAddressSend?.zip_code
					: rate.destinationAddressRate?.zip_code,
				zip_origin: sendFlow ? send.originAddressSend?.zip_code : rate.originAddressRate?.zip_code
			};

			if (dcoords) {
				payload.coordinates_destination = dcoords;
			}

			if (ocoords) {
				payload.coordinates_origin = ocoords;
			}

			if (neighborhood_orgin) {
				payload.neighborhood_origin = neighborhood_orgin;
			}

			if (neighborhood_destionation) {
				payload.neighborhood_destination = neighborhood_destionation;
			}

			if (state_destination) {
				payload.state_destination = state_destination;
			}

			if (city_destination) {
				payload.city_destination = city_destination;
			}

			if (simulatedUser.simulatedUser) {
				payload.user_id = simulatedUser.simulatedUser.id;
			}

			const result = await axiosInstance.post('/api/rate/label', payload);

			const services = result.data.rates as ServiceResponse[];

			const localCarriers: string[] = [];
			services.forEach((s) => localCarriers.push(s.carrier));

			dispatch({
				type: SET_UNIQUE_CARRIER_SERVICES_FLOW,
				carriers: [...new Set(localCarriers)]
			});
			dispatch({ type: SET_BATCH_ID, batchId: result.data.batch_id });

			dispatch({ type: SET_FILTERED_SERVICES, services });
			dispatch({ type: POST_RATE_DATA, services });
			dispatch({ type: FETCH_SUCCESS_DATA });

			// set carriers for consolidated
			const encamino = services.find((s) => s.carrier === 'encamino');
			if (encamino) {
				dispatch({
					type: SET_CARRIERS_CONSOLIDATED,
					carriers: encamino.carriers as string[]
				});
			}
		} catch (error) {
			let errorMessage = 'No se pudo cotizar, intente más tarde';
			if (axios.isAxiosError(error)) {
				if (
					error.response?.data?.message === 'WRONG_DATA' &&
					!!error.response?.data?.description.length
				) {
					const descriptionList = error.response?.data?.description
						?.map((item: { [key: string]: string }) => Object.values(item))
						.flat();
					if (
						descriptionList.some((item: string) =>
							item.includes('is not allowed for this measurement')
						)
					) {
						errorMessage =
							'Las medidas superan lo permitido. Verifica las medidas y la unidad de medida.';
					}
				}
			}
			dispatch({
				type: FETCH_FAIL_DATA,
				message: errorMessage
			});
			notification && notification(errorMessage);
		}
	};

// TODO move the pickup actions
export const postPickUp =
	(notify?: (message: string, severity?: 'error' | 'success') => void) =>
	async (dispatch: Dispatch<DataActions | PickUpActions>, getStore: () => RootStore) => {
		const { pickup, simulatedUser } = getStore();
		try {
			dispatch({ type: FETCH_INIT_DATA });

			const resultLocation: { [key: string]: any } = {};

			Object.entries({ ...pickup.addressPickUp }).forEach(([key, value]) => {
				if (value !== undefined && value !== null && value !== '') {
					resultLocation[key] = value;
				}
			});

			const payload: any = {
				parcel_numbers: pickup.labelsPickup.map((l) => l.parcel_number),
				date: (pickup.datePickUp as Date).toISOString().split('T')[0], // .toLocaleDateString().replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, '$3-$1-$1'),
				earliest_time: (pickup.startTimePickUp as Date)
					.toTimeString()
					.split(' ')[0]
					.replace(/(\d{1,2}):(\d{1,2}):(\d{1,2})/, '$1:$2'),
				latest_time: (pickup.endTimePickUp as Date)
					.toTimeString()
					.split(' ')[0]
					.replace(/(\d{1,2}):(\d{1,2}):(\d{1,2})/, '$1:$2'),
				packages: pickup.labelsPickup.reduce((prev, current) => {
					// @ts-ignore
					for (const p of current.packages) {
						// @ts-ignore
						prev.push(p);
					}
					return prev;
				}, []),

				location: resultLocation
			};

			payload.location.contact_name = pickup.addressPickUp?.contact?.name;
			payload.location.phone_number = pickup.addressPickUp?.contact?.phone_number;
			payload.location.email = pickup.addressPickUp?.contact?.email;
			payload.location.coordinates = pickup.addressPickUp?.coordinates || undefined;

			payload.location.suite_number = pickup.addressPickUp?.suite_number || undefined;
			payload.location.reference = pickup.addressPickUp?.reference || undefined;
			payload.location.reference_streets = pickup.addressPickUp?.reference_streets || undefined;
			payload.location.country = 'Mexico';
			payload.collect_in = pickup.indicationsPickUp || undefined;
			payload.indications = pickup.extraIndicationsPickUp || undefined;
			payload.location.number = pickup.addressPickUp?.number || 'S/N';
			delete payload.location?.street1;
			delete payload.location?.street2;
			delete payload.location?.street3;

			// delete payload.location.alias;

			if (simulatedUser.simulatedUser) {
				payload.user_id = simulatedUser.simulatedUser.id;
			}

			await axiosInstance.post(`/api/label/${pickup.carrierPickup}/pickup`, payload);
			dispatch({ type: FETCH_SUCCESS_DATA });
			// TODO remove?
			dispatch({ type: POST_PICKUP_DATA, response: 'si' });
			dispatch({ type: CAN_MOVE_TO_BOOKLET_PICKUP, value: true });
			notify && notify('La recolección fue generada con éxito', 'success');
		} catch (error) {
			if (axios.isAxiosError(error)) {
				notify &&
					notify(
						typeof error.response?.data?.description === 'string'
							? error.response?.data?.description
							: 'No se pudo generar la recolección, intente más tarde'
					);
			}
			dispatch({
				type: FETCH_FAIL_DATA,
				message: 'No se pudo generar la recolección, intente más tarde'
			});
		}
	};

export const getUserBalance = async (
	isRootOrAgent: boolean,
	setAvailableBalance: (balance: number | null) => void,
	customerId?: number,
	userId?: number
) => {
	try {
		const url = userId
			? `/api/dispersion/balance/users?customer_id=${customerId}`
			: isRootOrAgent
			? `/api/dispersion/balance?customer_id=${customerId}`
			: '/api/dispersion/balance';
		const { data } = await axiosInstance.get(url);
		if (userId) {
			const userBalance = data.data?.find((item: any) => item.user_id === userId)?.remaining;
			setAvailableBalance(userBalance ?? 0);
		} else if (isRootOrAgent) {
			const userBalance = data?.remaining;
			setAvailableBalance(userBalance ?? 0);
		} else {
			setAvailableBalance(data?.remaining);
		}
	} catch (error) {
		console.error(error);
	}
};

// TODO move to send actions
export const postLabel =
	(notify: (message: string, severity?: 'error' | 'success' | 'info' | 'warning') => void) =>
	async (dispatch: Dispatch<DataActions>, getStore: () => RootStore) => {
		const { send, simulatedUser } = getStore();

		try {
			dispatch({ type: FETCH_INIT_DATA });
			dispatch({ type: CLEAR_PARCEL_NUMBERS });

			if (!(send.originAddressSend?.tax_id && send.destinationAddressSend?.tax_id)) {
				notify(
					'Para crear la guía es necesario que la dirección de origen y dirección destino cuenten con RFC'
				);
				dispatch({ type: FETCH_SUCCESS_DATA });
				return;
			}

			const serviceSend = send.serviceSend!;

			const pkgsPaylod = send.currentPackagesSend.reduce((result: any, p) => {
				let finalInsurance = 0;

				if (send.showInsuranceOnSummary && p.insurance && p.insurance > 0) {
					finalInsurance = p.insurance;
				}
				return result.concat(
					new Array(p.quantity).fill(p).map((p) => {
						const packageType =
							p.type?.toLowerCase() === 'sobre' &&
							!p.content?.toLowerCase()?.includes('documentos') &&
							serviceSend?.carrier?.toLowerCase() === CARRIERS.ESTAFETA
								? 'Caja'
								: p.type;
						const payloadData: any = {
							length: p.length,
							weight: p.weight,
							height: p.height,
							width: p.width,
							type: packageType,
							content: p.content,
							description: p.description ? p.description : p.content,
							is_irregular_delivery: p.is_irregular_delivery ?? false,
							measurement: p.measurement
						};
						if (finalInsurance > 0) {
							payloadData.insurance = finalInsurance;
						}
						if (
							(serviceSend.carrier === 'estafeta' &&
								serviceSend.service_description.includes('LTL')) ||
							serviceSend.carrier === 'fedex' ||
							(p.type === 'Tarima' && serviceSend.carrier === 'paquetexpress')
						) {
							const merchandiseList = p.merchandise.map((item: Partial<Merchandise>) => {
								return {
									id: item.id,
									currency: item.currency,
									product_code: item.product_code,
									quantity: item.quantity,
									unit_code: item.unit_code,
									value: item.value
								};
							});

							payloadData.merchandise = merchandiseList;
						}
						return payloadData;
					})
				);
			}, []);

			//Find out if the origin address contains an phone extension. If it doesn't have the extension field, it is not sent to the db
			const formattedOriginType = {
				alias: send.originAddressSend?.alias,
				tax_id: send.originAddressSend?.tax_id.toUpperCase(),
				type: send.originAddressSend?.type || undefined,
				number: send.originAddressSend?.number || undefined,
				country: capitalize(removeStress(send.originAddressSend?.country)),
				suite_number: send.originAddressSend?.suite_number || undefined,
				reference: send.originAddressSend?.reference
					? capitalizeAll(send.originAddressSend?.reference)
					: undefined,
				reference_streets: send.originAddressSend?.reference_streets
					? capitalizeAll(send.originAddressSend?.reference_streets)
					: undefined,
				street:
					serviceSend.carrier === 'dhl' || serviceSend.carrier === 'estafeta'
						? capitalizeAll(send.originAddressSend?.street1)
						: capitalizeAll(send.originAddressSend?.street),
				zip_code: send.originAddressSend?.zip_code,
				coordinates: send.originAddressSend?.coordinates || undefined,
				neighborhood: send.originAddressSend?.neighborhood,
				city: send.originAddressSend?.city,
				state: send.originAddressSend?.state,
				contact_name: capitalizeAll(send.originAddressSend?.contact?.name),
				business_name: send.originAddressSend?.business_name?.toUpperCase(),
				email: send.originAddressSend?.contact?.email,
				phone_number: send.originAddressSend?.contact?.phone_number,
				type_phone_number: send.originAddressSend?.contact?.type,
				extension: send.originAddressSend?.contact?.extension || undefined
			};

			//Find out if the destination address contains an phone extension. If it doesn't have the extension field, it is not sent to the db
			const formattedDestinationType = {
				alias: send.destinationAddressSend?.alias,
				tax_id: send.destinationAddressSend?.tax_id.toUpperCase(),
				type: send.destinationAddressSend?.type || undefined,
				number: send.destinationAddressSend?.number || undefined,
				country: capitalize(removeStress(send.destinationAddressSend?.country)),
				suite_number: send.destinationAddressSend?.suite_number || undefined,
				reference: send.destinationAddressSend?.reference || undefined,
				reference_streets: send.destinationAddressSend?.reference_streets || undefined,
				street:
					serviceSend.carrier === 'dhl' || serviceSend.carrier === 'estafeta'
						? send.destinationAddressSend?.street1
						: send.destinationAddressSend?.street,
				zip_code: send.destinationAddressSend?.zip_code,
				coordinates: send.destinationAddressSend?.coordinates || undefined,
				neighborhood: send.destinationAddressSend?.neighborhood,
				city: send.destinationAddressSend?.city,
				state: send.destinationAddressSend?.state,
				contact_name: capitalizeAll(send.destinationAddressSend?.contact?.name),
				phone_number: send.destinationAddressSend?.contact?.phone_number,
				type_phone_number: send.destinationAddressSend?.contact?.type,
				email: send.destinationAddressSend?.contact?.email,
				business_name: send.destinationAddressSend?.business_name?.toUpperCase(),
				extension: send.destinationAddressSend?.contact?.extension || undefined
			};

			const payload: any = {
				use_trading: send.value === 'prepaid',
				carrier: send.serviceSend?.carrier,
				batch_id: send.batchId,
				rate_id: send.serviceSend?.rate_id,
				service_id: send.serviceSend?.service_id,
				origin_info: formattedOriginType,
				destination_info: formattedDestinationType,
				packages: pkgsPaylod
			};

			switch (serviceSend.carrier) {
				case 'estafeta':
					payload.wants_to_save_output_type = send.wantsToSaveOutputTypeForEstafeta;
					payload.output_type = send.estafetaOutputType;

					payload.destination_info.street2 = send.destinationAddressSend?.street2 || undefined;
					payload.destination_info.street3 = send.destinationAddressSend?.street3 || undefined;
					payload.origin_info.street2 = send.originAddressSend?.street2 || undefined;
					payload.origin_info.street3 = send.originAddressSend?.street3 || undefined;
					break;
				case 'fedex':
					payload.wants_to_save_output_type = send.wantsToSaveOutputTypeForEstafeta;
					payload.output_type = send.estafetaOutputType;
					break;
				case 'dhl':
					delete payload.origin_info.number;
					delete payload.origin_info.suite_number;
					delete payload.destination_info.number;
					delete payload.destination_info.suite_number;

					let streetOrigin = [
						send.originAddressSend.street1,
						send.originAddressSend.street2,
						send.originAddressSend.street3
					]
						.join(' ')
						.trim();
					let streetDestination = [
						send.destinationAddressSend.street1,
						send.destinationAddressSend.street2,
						send.destinationAddressSend.street3
					]
						.join(' ')
						.trim();

					if (send.originAddressSend?.number) {
						streetOrigin += ` Ext. ${send.originAddressSend.number}`;
					}
					if (send.destinationAddressSend?.number) {
						streetDestination += ` Ext. ${send.destinationAddressSend.number}`;
					}
					if (send.originAddressSend?.suite_number) {
						streetOrigin += ` Int. ${send.originAddressSend.suite_number}`;
					}
					if (send.destinationAddressSend?.suite_number) {
						streetDestination += ` Int. ${send.destinationAddressSend.suite_number}`;
					}
					streetOrigin += ` Col. ${send.originAddressSend.neighborhood}`;
					streetDestination += ` Col. ${send.destinationAddressSend.neighborhood}`;

					const [streetOrigin1, streetOrigin2, streetOrigin3] = splitTextIntoLines({
						text: streetOrigin,
						maxCharactersPerLine: 45
					});
					const [streetDestination1, streetDestination2, streetDestination3] = splitTextIntoLines({
						text: streetDestination,
						maxCharactersPerLine: 45
					});

					payload.origin_info.street = streetOrigin1;
					payload.origin_info.street2 = streetOrigin2 || undefined;
					payload.origin_info.street3 = streetOrigin3 || undefined;
					payload.destination_info.street = streetDestination1;
					payload.destination_info.street2 = streetDestination2 || undefined;
					payload.destination_info.street3 = streetDestination3 || undefined;
					// payload.destination_info.street2 = send.destinationAddressSend?.street2 || undefined;
					// payload.destination_info.street3 = send.destinationAddressSend?.street3 || undefined;
					// payload.origin_info.street2 = send.originAddressSend?.street2 || undefined;
					// payload.origin_info.street3 = send.originAddressSend?.street3 || undefined;
					break;

				case 'paquetexpress':
					payload.wants_to_save_output_type = send.wantsToSaveOutputTypeForEstafeta;
					payload.output_type = send.estafetaOutputType;
					break;
			}

			// Ocurre: to destination office
			if (!!send.destinationAddressSend.ocurre_office && serviceSend.carrier === 'paquetexpress') {
				payload.destination_info.ocurre_office = send.destinationAddressSend.ocurre_office;
				payload.destination_info.coordinates = send.destinationAddressSend?.coordinates
					? Object.values(send.destinationAddressSend?.coordinates).join(',')
					: undefined;
			}

			if (send.reference) {
				payload.reference = send.reference;
			}

			if (send.additionalInfo) {
				payload.additional_info = send.additionalInfo;
			}

			if (send.channel) {
				payload.channel = send.channel;
			}

			if (simulatedUser.simulatedUser) {
				payload.user_id = simulatedUser.simulatedUser.id;
			}

			if (send.notifications) {
				payload.notification_contacts = send.notifications;
			}

			if (send.business) {
				payload.business_id = send.business.id;
			}
			let total = serviceSend!.label_price || 0 + serviceSend?.insurance;
			total += serviceSend?.irregular_delivery_price || 0;
			total += serviceSend?.reissue_price || 0;
			total += serviceSend?.overweight_price || 0;

			const result = await axiosInstance.post('/api/label', payload);

			dispatch({ type: FETCH_SUCCESS_DATA });
			dispatch({
				type: POST_GUIDE_DATA,
				labels: result.data.labels.map((label: any) => ({
					...payload,
					parcelNumber: label.parcel_number,
					parcel_number: label.parcel_number,
					pdf: label.pdf,
					origin: payload.origin_info,
					destination: payload.destination_info
				})),
				total
			});
			notify(`Fueron creadas ${result.data.labels.length} guías con éxito.`, 'success');
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (typeof error === 'string') notify(error);
				const errorStatus = error?.response?.status ?? undefined;
				const errorDescription = error?.response?.data?.description ?? 'Hubo un error';

				if (errorStatus === 402) {
					notify('No hay guias disponibles para crearla, pide que te asignen más');
				} else if (errorStatus === 503 && errorDescription !== 'Not further details') {
					notify(errorDescription);
				} else if (errorStatus === 405 && errorDescription !== 'Not further details') {
					notify(errorDescription);
				} else if (errorDescription === 'Not further details' && error?.response?.data?.code) {
					notify(error?.response?.data?.code);
				} else {
					notify(
						'No fue posible generar la guia, contacte al equipo de Encamino para más información'
					);
				}
				dispatch({ type: FETCH_FAIL_DATA, message: '' });
			}
		} finally {
			// Clear temporal addresses
			dispatch({ type: CLEAR_ADDRESS_DATA });
		}
	};

export const resetCreatedLabels = () => (dispatch: Dispatch<DataActions>) => {
	dispatch({ type: CLEAR_PARCEL_NUMBERS });
};

export const setCarriersList = (carriers: CarrierList) => (dispatch: Dispatch<DataActions>) => {
	dispatch({ type: SET_CARRIERS, carriers });
};
