import React, { useEffect, useState } from 'react';
import {
	Button,
	ButtonGroup,
	createStyles,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	makeStyles,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	withStyles
} from '@material-ui/core/';
import { Edit as EditIcon, XSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import PackageTypeField from '../Inputs/PackageTypeField';
import PackageContentField from '~components/Inputs/PackageContentField';

import {
	removePackageSend,
	updatePkgSend,
	setValidateFormPkgsSend,
	editPackage,
	removePackageRate,
	updatePkgRate,
	setValidateFormPkgsRate,
	showInsuranceServicesFlow
} from '../../store/actions';

import { REGEX_POSITIVE_FLOAT_NUMBERS } from '../../util';
import { Package } from '../../store/actions/ActionTypes';
import { RootStore } from '../../store/store';
import { Checkbox } from '@material-ui/core';

interface Column {
	id:
		| 'quantity'
		| 'insurance'
		| 'type'
		| 'dimensions'
		| 'height'
		| 'width'
		| 'length'
		| 'measurement'
		| 'weight'
		| 'actions'
		| 'content'
		| 'is_irregular_delivery';
	label: string;
	minWidth?: number;
	maxWidth?: number;
	width?: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			fontWeight: 'bold'
		},
		irregularLabel: {
			fontSize: '12px',
			'&>span': {
				fontSize: 'inherit'
			}
		}
	})
);

const TableCellWithOutPadding = withStyles({
	root: {
		padding: '5px 3px'
	}
})(TableCell);

type FormValues = {
	pkgs: {
		quantity: number;
		height: number;
		width: number;
		length: number;
		weight: number;
		measurement: string;
		insurance: number;
		package_type: { title: string };
	}[];
};
interface Props {
	showInsurance: boolean;
	data: Package[];
}

/**
 * Esta tabla es la que se usa para preparar los paquetes
 * para un Send o Rate, se manda a llamar en PackageCard
 */
const TablePackagesCU: React.FC<Props> = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { currentFlow } = useSelector((state: RootStore) => state.flow);
	const { isTouchedRate } = useSelector((state: RootStore) => state.rate);
	const { isTouchedSend } = useSelector((state: RootStore) => state.send);

	const { register, errors, control, formState, handleSubmit, clearErrors, setValue } =
		useForm<FormValues>({
			mode: 'all'
		});

	const { isValid } = formState;

	const [columns, setColumns] = useState<Column[]>([
		{ id: 'quantity', label: 'Cantidad', width: 50 },
		{ id: 'type', label: 'Tipo' },
		{ id: 'dimensions', label: 'Caracteristicas', minWidth: 200 },
		{ id: 'content', label: 'Contenido', minWidth: 175 },
		{
			id: 'is_irregular_delivery',
			label: 'Manejo Especial',
			maxWidth: props.showInsurance ? 40 : 80,
			minWidth: 40
		},
		{ id: 'actions', label: 'Acciones', width: 50 }
	]);

	// listen when should validate
	// trigger by the 'Continuar' button
	useEffect(() => {
		if (currentFlow === 'send') {
			dispatch(setValidateFormPkgsSend(isValid));
		} else if (currentFlow === 'rate') {
			dispatch(setValidateFormPkgsRate(isValid));
		}
	}, [isValid, dispatch, currentFlow]);

	// Show error feedback when touched
	useEffect(() => {
		if (isTouchedRate || isTouchedSend) {
			handleSubmit(() => {})();
		}
	}, [isTouchedRate, isTouchedSend, handleSubmit]);

	// Handle add new column for insurance
	// Dispatch if the insurance will be available
	useEffect(() => {
		if (props.showInsurance) {
			setColumns((prev) => {
				return [
					...prev.slice(0, 5),
					{ id: 'insurance', label: 'Seguro', width: 119 },
					...prev.slice(5, prev.length)
				];
			});
		} else if (!props.showInsurance) {
			// this may look redundant but works because the re-renders
			setColumns((prev) => {
				return prev.filter((item) => item.id !== 'insurance');
			});
		}
	}, [props.showInsurance, dispatch]);

	// Remove the insurance from the values
	useEffect(() => {
		if (!props.showInsurance) {
			props.data.forEach((p) => {
				if (currentFlow === 'send') {
					dispatch(updatePkgSend(p.pkg_id, { insurance: 0 }));
				} else if (currentFlow === 'rate') {
					dispatch(updatePkgRate(p.pkg_id ? p.pkg_id : p.id, { insurance: 0 }));
				}
			});
			dispatch(showInsuranceServicesFlow(false));
		} else if (props.showInsurance) {
			dispatch(showInsuranceServicesFlow(true));
		}
	}, [props.showInsurance, props.data, currentFlow, dispatch]);

	const isContentUninsurable = (pkgContent: string) => {
		return (
			pkgContent === 'Accesorios' ||
			pkgContent === 'Artesanías' ||
			pkgContent === 'Documentos' ||
			pkgContent === 'Fragancias' ||
			pkgContent === 'Muebles' ||
			pkgContent === 'Otro' ||
			pkgContent === 'Muestras'
		);
	};

	return (
		<Paper style={{ width: '100%' }}>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									className={classes.root}
									key={column.id}
									style={{
										minWidth: column.minWidth ?? 'unset',
										width: column.width ?? 'unset',
										maxWidth: column.maxWidth ?? 'unset',
										padding: column.id !== 'type' ? 4 : 8
									}}
								>
									<div style={{ display: 'flex', justifyContent: 'center' }}>{column.label}</div>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.data.map((row, i) => {
							const packageToRateId = row.pkg_id ? row.pkg_id : row.id;
							return (
								<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
									{columns.map((column) => {
										switch (column.id) {
											case 'quantity':
												return (
													<TableCellWithOutPadding key={column.id} style={{ textAlign: 'center' }}>
														<TextField
															autoFocus //={currentFlow === 'send'}
															autoComplete='off'
															name={`pkgs[${i}].quantity`}
															inputRef={register({
																required: 'Ingrese cantidad',
																min: {
																	value: 1,
																	message: 'Medida no válida'
																},
																max: {
																	value: 99,
																	message: 'Max: 99'
																},
																pattern: {
																	value: /^[0-9]+$/,
																	message: 'Sólo numeros'
																}
															})}
															error={Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.quantity)}
															helperText={
																errors?.pkgs?.length && errors?.pkgs[i]?.quantity?.message
															}
															defaultValue={row?.quantity ?? 1}
															onChange={(e) => {
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			quantity: parseInt(e.target.value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			quantity: parseInt(e.target.value) || 0
																		})
																	);
																}
															}}
															variant='filled'
															style={{ width: column.width ?? 'unset' }}
															label='#'
														/>
													</TableCellWithOutPadding>
												);
											case 'dimensions':
												return (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															display: 'flex',
															justifyContent: 'center',
															padding: '16px 0 !important',
															marginTop: '4px'
														}}
													>
														<TextField
															autoComplete='off'
															disabled={row.type === 'Sobre'}
															name={`pkgs[${i}].length`}
															inputRef={register({
																required: 'Ingrese Largo',
																min: {
																	value: 0.1,
																	message: 'Medida no válida'
																},

																pattern: {
																	value: row.type === 'Sobre' ? /-/ : REGEX_POSITIVE_FLOAT_NUMBERS,
																	message: 'Medida no válida'
																}
															})}
															error={Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.length)}
															helperText={errors?.pkgs?.length && errors?.pkgs[i]?.length?.message}
															onChange={(e) => {
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			length: parseFloat(e.target.value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			length: parseFloat(e.target.value) || 0
																		})
																	);
																}
															}}
															label='Largo'
															defaultValue={row.type === 'Sobre' ? '-' : row.length.toString()}
															// defaultValue={row.type === 'Sobre' ? '-' : row.length.toString()}
															variant='filled'
															style={{ width: 60 }}
														/>
														&nbsp;
														<TextField
															autoComplete='off'
															disabled={row.type === 'Sobre'}
															name={`pkgs[${i}].width`}
															inputRef={register({
																required: 'Ingrese Ancho',
																min: {
																	value: 0.1,
																	message: 'Medida no válida'
																},
																pattern: {
																	value: row.type === 'Sobre' ? /-/ : REGEX_POSITIVE_FLOAT_NUMBERS,
																	message: 'Medida no válida'
																}
															})}
															error={Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.width)}
															helperText={errors?.pkgs?.length && errors?.pkgs[i]?.width?.message}
															onChange={(e) => {
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			width: parseFloat(e.target.value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			width: parseFloat(e.target.value) || 0
																		})
																	);
																}
															}}
															label='Ancho'
															defaultValue={row.type === 'Sobre' ? '-' : row.width.toString()}
															// defaultValue={row.type === 'Sobre' ? '-' : row.width.toString()}
															variant='filled'
															style={{ width: 60 }}
														/>
														&nbsp;
														<TextField
															autoComplete='off'
															disabled={row.type === 'Sobre'}
															name={`pkgs[${i}].height`}
															inputRef={register({
																required: 'Ingrese Alto',
																min: {
																	value: 0.1,
																	message: 'Medida no válida'
																},

																pattern: {
																	value: row.type === 'Sobre' ? /-/ : REGEX_POSITIVE_FLOAT_NUMBERS,
																	message: 'Medida no válida'
																}
															})}
															error={Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.height)}
															helperText={errors?.pkgs?.length && errors?.pkgs[i]?.height?.message}
															onChange={(e) => {
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			height: parseFloat(e.target.value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			height: parseFloat(e.target.value) || 0
																		})
																	);
																}
															}}
															variant='filled'
															style={{ width: 60 }}
															label='Alto'
															defaultValue={row.type === 'Sobre' ? '-' : row.height.toString()}

															// defaultValue={row.type === 'Sobre' ? '-' : row.height.toString()}
														/>
														&nbsp;
														<FormControl variant='filled'>
															<InputLabel htmlFor='filled-age-native-simple'>Unidad</InputLabel>
															<Select
																native
																label='Unidad'
																disabled={row.type === 'Sobre'}
																defaultValue={row.measurement}
																error={Boolean(
																	errors?.pkgs?.length && errors?.pkgs[i]?.measurement
																)}
																inputRef={register({
																	pattern: {
																		value: /^c?m$/,
																		message: 'Valores no válidos'
																	}
																})}
																inputProps={{
																	name: `pkgs[${i}].measurement`
																}}
																onChange={(e) => {
																	if (currentFlow === 'send') {
																		dispatch(
																			updatePkgSend(row.pkg_id, {
																				measurement: e.target.value as string
																			})
																		);
																	} else if (currentFlow === 'rate') {
																		dispatch(
																			updatePkgRate(packageToRateId, {
																				measurement: e.target.value as string
																			})
																		);
																	}
																}}
															>
																<option value={'cm'}>cm</option>
																<option value={'m'}>m</option>
															</Select>
															<FormHelperText>
																{errors?.pkgs?.length && errors?.pkgs[i]?.measurement?.message}
															</FormHelperText>
														</FormControl>
														&nbsp;
														<TextField
															autoComplete='off'
															name={`pkgs[${i}].weight`}
															inputRef={register({
																required: 'Ingrese Peso',
																min: {
																	value: 1,
																	message: 'Medida no válida'
																},
																max: {
																	value: row.type === 'Sobre' ? 5 : 100000,
																	message: 'Peso no válido'
																},
																pattern: {
																	value: REGEX_POSITIVE_FLOAT_NUMBERS,
																	message: 'Medida no válida'
																}
															})}
															error={Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.weight)}
															helperText={errors?.pkgs?.length && errors?.pkgs[i]?.weight?.message}
															onChange={(e) => {
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			weight: parseFloat(e.target.value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			weight: parseFloat(e.target.value) || 0
																		})
																	);
																}
															}}
															label='Peso'
															defaultValue={row.weight}
															variant='filled'
															style={{ maxWidth: 94, minWidth: props.showInsurance ? 74 : 94 }}
															InputProps={{
																endAdornment: (
																	<InputAdornment
																		style={{
																			height: '100%',
																			display: 'flex',
																			alignSelf: 'flex-end',
																			paddingBottom: '6px'
																		}}
																		position='end'
																	>
																		Kg
																	</InputAdornment>
																)
															}}
														/>
													</TableCell>
												);
											case 'insurance':
												return (
													<TableCellWithOutPadding key={column.id}>
														<TextField
															disabled={isContentUninsurable(row.content)}
															defaultValue={isContentUninsurable(row.content) ? '-' : row.insurance}
															autoComplete='off'
															name={`pkgs[${i}].insurance`}
															inputRef={register(
																!isContentUninsurable(row.content)
																	? {
																			required: 'Ingrese Valor',
																			pattern: {
																				value: /^[1-9]+|0$/,
																				message: 'Cantidad no válida'
																			}
																	  }
																	: {}
															)}
															error={
																!isContentUninsurable(row.content) &&
																Boolean(errors?.pkgs?.length && errors?.pkgs[i]?.insurance)
															}
															helperText={
																!isContentUninsurable(row.content) &&
																errors?.pkgs?.length &&
																errors?.pkgs[i]?.insurance?.message
															}
															onChange={(e) => {
																if (isContentUninsurable(row.content)) return;
																const value = e.target.value
																	.split('')
																	.filter((item) => item !== ',' && item !== '$')
																	.join('');
																if (currentFlow === 'send') {
																	dispatch(
																		updatePkgSend(row.pkg_id, {
																			insurance: parseFloat(value) || 0
																		})
																	);
																} else if (currentFlow === 'rate') {
																	dispatch(
																		updatePkgRate(packageToRateId, {
																			insurance: parseFloat(value) || 0
																		})
																	);
																}
															}}
															variant='filled'
															style={{ width: column.width ?? 'unset' }}
															label={
																<span style={{ marginLeft: -2 }}>
																	Valor por {row.type ?? 'Empaque'}
																</span>
															}
															InputProps={{
																endAdornment: (
																	<InputAdornment
																		style={{
																			height: '100%',
																			display: 'flex',
																			alignSelf: 'flex-end',
																			paddingBottom: '7px',
																			marginLeft: 0
																		}}
																		position='end'
																	>
																		MXN
																	</InputAdornment>
																),
																startAdornment: (
																	<InputAdornment
																		style={{
																			height: '100%',
																			display: 'flex',
																			alignSelf: 'flex-end',
																			paddingBottom: '7px',
																			marginLeft: 0
																		}}
																		position='end'
																	>
																		$
																	</InputAdornment>
																)
															}}
														/>
													</TableCellWithOutPadding>
												);
											case 'actions':
												return (
													<TableCell key={column.id}>
														<ButtonGroup size='small'>
															{currentFlow === 'send' && (
																<Button
																	onClick={() => {
																		dispatch(editPackage(row));
																		dispatch(removePackageSend(row.pkg_id));
																		history.push(`/paquete/${row.id}`);
																	}}
																>
																	<EditIcon />
																</Button>
															)}
															<Button
																onClick={() => {
																	if (currentFlow === 'rate') {
																		dispatch(removePackageRate(packageToRateId));
																	} else if (currentFlow === 'send') {
																		dispatch(removePackageSend(row.pkg_id));
																	}
																}}
															>
																<XSquare />
															</Button>
														</ButtonGroup>
													</TableCell>
												);
											case 'is_irregular_delivery':
												return (
													<TableCell key={column.id}>
														<div
															style={{
																maxWidth: column.maxWidth ?? 'unset',
																minWidth: column.minWidth ?? 'unset'
															}}
														>
															<Checkbox
																color='primary'
																checked={row.is_irregular_delivery}
																onChange={(e) => {
																	if (currentFlow === 'send') {
																		dispatch(
																			updatePkgSend(row.pkg_id, {
																				is_irregular_delivery: e.target.checked
																			})
																		);
																	} else if (currentFlow === 'rate') {
																		dispatch(
																			updatePkgRate(packageToRateId, {
																				is_irregular_delivery: e.target.checked
																			})
																		);
																	}
																}}
															/>
														</div>
													</TableCell>
												);

											case 'type':
												if (currentFlow === 'rate') {
													return (
														<TableCell key={column.id}>
															<PackageTypeField
																callBack={(t) => {
																	dispatch(updatePkgRate(packageToRateId, { type: t }));
																	clearErrors(`pkgs[${i}].weight`);
																}}
																autoFocus={row[column.id] === null}
																control={control}
																errors={errors}
																defaultValue={row[column.id]}
																fieldName={`pkgs[${i}].package_type`}
															/>
														</TableCell>
													);
												}
												if (currentFlow === 'send') {
													return <TableCell key={column.id}>{row[column.id]}</TableCell>;
												}
												return <>Caso no manejado</>;

											case 'content':
												return (
													<TableCellWithOutPadding key={column.id}>
														<div
															style={{
																maxWidth: column.maxWidth ?? 'unset',
																width: column.width ?? 'unset'
															}}
														>
															<PackageContentField
																control={control}
																errors={errors}
																defaultValue={row.content}
																setValue={setValue}
																setRequireDescription={() => {}}
																packageId={packageToRateId}
																fieldName={`pkgs[${i}].package_content`}
															/>
														</div>
													</TableCellWithOutPadding>
												);

											default:
												return (
													<TableCell key={column.id} style={{ padding: '0px 6px' }}>
														{row[column.id]}
													</TableCell>
												);
										}
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default TablePackagesCU;
